import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getFileName } from "../utils"
import Modal from "react-modal"

export const News = ({
  imgSrc = "media_placeholder.jpg",
  heading = "-",
  description = "-",
  link = {},
}) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const openNewsModal = () => {
    setIsOpen(true)
  }
  const closeNewsModal = () => {
    setIsOpen(false)
  }
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 1920) {
              originalImg
            }
          }
        }
      }
    }
  `)

  data.allImageSharp.edges.forEach(element => {
    if (getFileName(element.node.fluid.originalImg) === imgSrc) {
      imgSrc = element.node.fluid.originalImg
    }
  })
  return (
    <div className="media-box ">
      <div className="content-container" onClick={openNewsModal}>
        <div className="main-img">
          <img src={imgSrc} alt="news" />
        </div>
        <div className="content">
          <h5 className="links">
            <a className="box-label">{heading}</a>
          </h5>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeNewsModal}
        contentLabel="Example Modal"
        className="news-box-modal"
      >
        <div className="modal-content">
          <img className="modal-img" src={imgSrc} alt="images" />

          <p className="modal-description">{description}</p>
          <div className="modal-footer">
            <button className="close-button" onClick={() => setIsOpen(false)}>
              Close
            </button>
            <button className="more">
              {Object.keys(link).map(element => (
                <a className="learn-more" target="_blank" href={link[element]}>
                  {element}
                </a>
              ))}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default News
export const news_data_2021 = [
  {
    heading:
      "Intel Software's recent article spotlights Touchstone, our revolutionary cloud test suite system.  Intel's endorsement underscores Touchstone's significance in addressing the evolving needs of cloud infrastructure.",
    description:
      "Intel Software's recent article spotlights Touchstone, our revolutionary cloud test suite system. With a focus on assessing performance, functionality, robustness, and stability of cloud environments, Touchstone emerges as a crucial solution amidst the complexities of modern data centres. As virtualization becomes increasingly prevalent, maintenance and performance management pose significant challenges due to diverse infrastructures and varied operating systems. Touchstone stands out as a comprehensive Telco Cloud test suite system, offering unparalleled capabilities in navigating these intricate landscapes. By providing a thorough assessment of cloud environments, it enables organizations to optimize their operations, enhance reliability, and streamline performance management. Intel's endorsement underscores Touchstone's significance in addressing the evolving needs of cloud infrastructure, reaffirming its status as an indispensable tool for enterprises navigating the complexities of next-gen data centres.",
    imgSrc: "news_intel_software.png",
    link: {
      "Learn More":
        "https://networkbuilders.intel.com/solutionslibrary/touchstone-by-voereir?medium=satg_23_18&cid=iosm&source=linkedin&campid=intel_software_developer_experiences_worldwide&content=100005390352611&icid=satg-dep-campaign&linkId=100000250016835",
    },
  },
  {
    heading:
      " Exciting news! 🚀 VoerEir AB is honored to be named among the Top 100 Edge Computing Companies to watch in 2024 by STL Partners, TouchStone is recognised as one of them.",
    description:
      "Exciting news! 🚀 VoerEir AB is thrilled to be named among the Top 100 Edge Computing Companies to watch in 2024 by STL Partners.Our revolutionary product, 'Touchstone,' is at the forefront, empowering Telco operators to validate diverse edge cloud infrastructures. This ensures flawless performance of cutting-edge network applications, like Cloud RAN, at the Edge, where compute resources are limited.",
    imgSrc: "news_stlpartner.png",
    link: {
      "Learn More":
        "https://stlpartners.com/articles/edge-computing/edge-computing-companies-2024/",
    },
  },
  {
    heading:
      "VoerEir actively and successfully participated in the O-RAN Spring Plugfest June 2023, employing the powerful Touchstone tool for comprehensive performance testing of the O-Cloud platform.",
    description:
      "VoerEir once again successfully participated at the prestigious global O-RAN Plugfest, in June 2023, using the outstanding capabilities of the testing tool Touchstone to conduct thorough and meticulous performance evaluations for the O-Cloud platform.",
    imgSrc: "news_plugfest.png",
    link: {
      "Learn More":
        "https://plugfestvirtualshowcase.o-ran.org/2023/O-RAN_Global_PlugFest_hosted_by_Deutsche_Telekom_EANTC_EURECOM_Orange_Vodafone",
    },
  },
  {
    heading: "VoerEir has earned it's place among the Top 100 Edge computing companies to watch in 2023.",
    description: "We are proud to communicate that VoerEir AB has been selected as one of the Top 100 Edge computing companies to watch in 2023,"+
     "according to STL Partners analysis and exclusive selection. Our product for Telco Cloud testing, 'Touchstone', will enable Telco operators"+
      "and vendors to validate different edge cloud infrastructures and guarantee the performance that new network applications, like Cloud RAN,"+
      "require at the Edge, where compute resources will be more limited. ",
    imgSrc: "news_STL_2023.png",
    link: {
      "Learn More":
        "https://stlpartners.com/articles/edge-computing/edge-computing-companies-2023/",
    },
  },
  {
    heading: "VoerEir and partners Aarna Networks, Rebaca Technologies, and Red Hat impressively demonstrate 'O-RAN orchestration on O-Cloud' at Mobile World Congress",
    description: "At the Mobile World Congress in Barcelona, VoerEir and its esteemed partners, Aarna Networks, Rebaca Technologies, and Red Hat, brilliantly showcase"+
     "their demonstration of 'O-RAN orchestration on O-Cloud,' specifically designed to optimize performance in hybrid environments.In This demo, O-Cloud"+
     "platforms were tested on-prem and public clouds against a performance baseline (CPU, Memory, Storage, Networking, …) with VoerEir’s “Touchstone” Automated Test System, "+
     "and the O-RAN standard specified test cases were executed for the O-RAN CNF workloads with Rebaca Abot. ",
    imgSrc: "news_mwc-2023-splash.png",
    link: {
      "Learn More":
        "https://www.virtualexhibition.o-ran.org/classic/generation/2023/category/open-ran-demonstrations/sub/open-cloud/237",
    },
  },
  {
    heading:
      "VoerEir's exceptional role in facilitating NFV transformation for operators is highlighted in eustartup.news, emphasizing their commitment to helping operators succeed.",
    description:
      "We are thrilled to announce that VoerEir AB has been prominently showcased in the Startup Showcase section of EUStartup.news for their exceptional contribution in empowering operators to achieve success with NFV transformation.",
    imgSrc: "news_eustartup.news.png",
    link: {
      "Learn More":
        "https://eustartup.news/startup-showcase-voereir-ab-helping-operators-succeed-with-nfv-transformation/",
    },
  },
  {
    heading:
      "VoerEir successfully participates in O-RAN global Plugfest using Touchstone for O-Cloud platform performance testing.",
    description:
      "VoerEir excels in its successful participation at the global O-RAN Plugfest, leveraging the cutting-edge capabilities"+
      "of Touchstone to conduct comprehensive performance testing for the O-Cloud platform.",
    imgSrc: "news_plugfest.png",
    link: {
      "Learn More":
        "https://www.businesswire.com/news/home/20221214005850/en/O-RAN-ALLIANCE-Advances-Testing-and-Integration-with-Successful-O-RAN-Global-PlugFest-Fall-2022",
    },
  },
  {
    heading:
      "Carlos Garcia Braschi joins VoerEir as its new CTO, bringing in rich experience and directions from the telecom operator world.",
    description:
      "Carlos Garcia Braschi has close to 30 years of experience"+
       "in the Information Communication Technology (ICT) sector. "+
      "During his career, Carlos has held multiple technology leadership positions working with leading edge-technologies, "+
      "especially in Telco Cloud systems. Prior to this Carlos was associated with Telefonica Spain as Telco Cloud (NFVI) Manager."+
      " Thomas Lindquist, the former CTO of VoerEir, has made the decision to retire after doing his incredible 37 years' work in the industry. "+
      "Voereir will continue to have Thomas as an advisor.",
    imgSrc: "new_CTO_newss.png",
    link: {
      "Learn More":
        "https://www.einnews.com/pr_news/576376181/voereir-ab-announce-a-new-cto-from-telefonica-spain-carlos-garc-a-braschi",
    },
  },
  {
    heading:
      "Mobileum and VoerEir Partner to Provide End-to-End Service Assurance and Network Function Virtualization Infrastructure Testing and Benchmarking",
    description:
      "The partnership between Mobileum and VoerEir creates the most comprehensive and powerful test platform for end-to-end Service Assurance and network function virtualization infrastructure (NFVI) benchmarking, certification, and validation. Combining Mobileum's SITE active test solution with VoerEir's TOUCHSTONE test suite provides a vertically integrated, full-stack testing solution that enables Communication Service Providers (CSPs) to test services and infrastructure from end-to-end. From the lab to the live network and from virtual and cloud-native network functions and applications (VNF/CNF) to the end device - all in a fully automated manner. Extensive RESTful API and automation capabilities allow the joint solution to be integrated into Telco Continuous Integration and Delivery (CI/CD) pipelines and provide an essential Continuous Testing (CT) component.",
    imgSrc: "Mobelium_VoerEir.png",
    link: {
      "Learn More":
        "https://finance.yahoo.com/news/mobileum-voereir-partner-end-end-010000365.html?guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAFX64z_ahsWvz3zDfCsaj3S9EJfgekKeZsSm8l6WNmVuALeqnxik-J1Ac7bmn6a-o65_XORfrnFAreDJiPGHqkAUPmj4Z9ptNr_UQh_lwhplmgD7NhuXlgEhFON2uMpFid_SNOVPfusmhHda3Svy9bld4_F5SsWvVoQz3L3USE0p&guccounter=2",
    },
  },
  {
    heading: "Featured in the 'Edge companies to watch in 2022' list.",
    description:
      "We are proud to announce that VoerEir AB has been listed as one of the “100 Edge computing companies to watch in 2022” by STL Partners",
    imgSrc: "news_top_100_2022.png",
    link: {
      "Learn More":
        "https://stlpartners.com/articles/edge-computing/edge-computing-companies-2022/",
    },
  },
  {
    heading:
      "Senior Ericsson Executive Javier Garcia-Gomez to lead VoerEir as new CEO starting November 1st, 2021.",
    description:
      "Javier Garcia-Gomez has more than 20 years of experience in the Information" +
      " Communication Technology (ICT) sector and has developed his career in various" +
      " Telecom suppliers such as Nortel,Alcatel-Lucent, and Ericsson. During his career," +
      " Javier has held multiple executive and leadershippositions in sales, business development," +
      " technology leadership, product management and network engineering.Javier has a wide international" +
      " experience, having worked in various countries such as Spain, Sweden, India, France, and the USA.",
    imgSrc: "new_CEO.png",
    link: {
      "Learn More":
        "https://www.einnews.com/pr_news/554070659/senior-ericsson-executive-javier-garc-a-g-mez-to-lead-edge-cloud-company-voereir-as-new-ceo",
    },
  },
  {
    heading: "Binero Group AB and VoerEir AB's collaboration strengthened.",
    description:
      "We are delighted to strengthen our cooperation with Binero," +
      "where we now also become a customer of their cloud infrastructure," +
      "that we are helping to build with our test automation product Touchstone and expertise.",
    imgSrc: "news_binero_new.png",
    link: {
      "Learn More": "https://lnkd.in/dpNdgTxp",
    },
  },
  {
    heading: "Intel & VoerEir Whitepaper Published on June 10, 2021",
    description:
      "VoerEir published a white paper in collaboration with Intel on network testing and DUT saturation. " +
      "This white paper demonstrates the packet generation capabilities in tests on Intel® Xeon® " +
      "Scalable processor servers using Touchstone, which saturates two 25 Gbps network interface " +
      "cards while using minimal compute resources, with 64-byte packets.",
    imgSrc: "news_intel_voereir_whitepaper.png",
    link: {
      "Learn More":
        "https://networkbuilders.intel.com/solutionslibrary/voereir-demonstrates-touchstone-for-testing-nfvi",
    },
  },
  {
    heading: "Featured in the 'Edge companies to watch in 2021' list.",
    description:
      "VoerEir AB has been listed as one of the “60 Edge computing companies to watch in 2021” by STL Partners",
    imgSrc: "news_top_60_edge.png",
    link: {
      "Learn More":
        "https://stlpartners.com/edge-computing/edge-computing-companies-to-watch-2021/",
    },
  },
  {
    heading:
      "Listed in Top 5 Network Function Virtualization (NFV) Solutions Impacting The Telecom Sector",
    description:
      "VoerEir AB has been recognised as one of the “5 Top Network Function Virtualization (NFV) Solutions Impacting The Telecom Sector” by StartUs insights",
    imgSrc: "Top5-Network-Function-Virtualization-Startups.png",
    link: {
      "Learn More":
        "https://www.startus-insights.com/innovators-guide/5-top-network-function-virtualization-nfv-solutions-impacting-the-telecom-sector",
    },
  },
  {
    heading:
      "VoerEir is Named in Intel’s Winners’ Circle for second time in a row",
    description:
      "VoerEir AB is honoured to be recognized by Intel’s Winners’ Circle Program as a Solution Partner for our contribution to the acceleration of #5G and virtual network transformation and by working closely with Intel and its Intel® Network Builders ecosystem.",
    imgSrc: "news_intel_circle.png",
    link: {
      "Learn More": "https://networkbuilders.intel.com/ecosystem",
    },
  },
]

export const archived_news_data = [
  {
    heading: "Collaboration with Binero Group AB.",
    description:
      "VoerEir is happy to announce the news of it's collaboration with Binero Group AB.",
    imgSrc: "news_binero.png",
    link: {
      "Learn More":
        "https://www.linkedin.com/posts/binero_binero-group-ab-enters-into-a-cooperation-activity-6720570040107712512-vESh",
    },
  },
  {
    heading: "Intel Webinar on Edge KPIs: Taking control of Performance",
    description:
      "Watch the recording of the Intel webinar on Edge Paradigms and relevant KPIs by out Director Technology, Ashok Kumar and Technical Project manager, Manik Sidana from the session held on the 6th of October 2020.",
    imgSrc: "news_intelWebinar.jpg",
    link: {
      "Learn More":
        "https://networkbuilders.intel.com/social-hub/webcast/edge-kpis-taking-control-of-performance",
    },
  },
  {
    heading:
      "VoerEir's Senior Vice President - listed in Top 30 Edge Computing Leaders",
    description:
      "A hearty congratulations to our Senior Vice President and Co-founder, Arif Khan for making to the elite Top 30 Edge Computing Leaders list as a leader and influencer in the Edge Computing and Cloud industry.",
    imgSrc: "news_arif.jpg",
    link: {
      "Learn More":
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&edid=ba2efacf-e0a8-45d2-889d-957626dfa812&pnum=34",
    },
  },
  {
    heading: "Webinar on Edge Paradigms and relevant KPIs",
    description:
      "Watch the recording of the webinar on Edge Paradigms and relevant KPIs by our VP, Arif Khan together with Aikaan labs from the session held on the 2nd of September 2020.",
    imgSrc: "news_aikaan.png",
    link: {
      "Learn More": "https://www.youtube.com/watch?v=8FdUSLcLY7A",
    },
  },
  {
    heading:
      "Selected by the National Informatics Center (NIC) to help to build the future Open Cloud Infrastructure in India",
    description:
      "VoerEir is very proud to be selected by the National Informatics Center (NIC) to help to build the future Open Cloud Infrastructure in India. This contract is an important milestone for VoerEir since it validates that the deep technical knowledge and the Product developed for Telecom Clouds is also applicable for other segments such as Governments and Smart Cities. VoerEir will continue on this journey to support the Edge Cloud Infrastructure deliver the exciting applications of the future.",
    imgSrc: "news_economictimes.jpg",
    link: {
      "Learn More":
        "https://telecom.economictimes.indiatimes.com/news/startup-by-former-ericsson-executive-bet-big-on-indian-telecom-market-bags-contract-from-nic-for-project-meghraj/77082275",
    },
  },
  {
    heading: "Principal Technical Lead at Kontour, for Edge KPIs",
    description:
      "We're proud to announce that VoerEir's Sr Vice President has been elected as the Principal Technical Lead for the VoerEir led initiative, Kontour. It’s a big milestone towards VoerEir's commitment to standardize KPIs for Edge Infrastructure.",
    imgSrc: "news_kontour.jpg",
    link: {
      "Learn More": "https://wiki.akraino.org/display/AK/KONTOUR",
    },
  },
  {
    heading: "VoerEir's Director of Technology - Featured on APAC50",
    description:
      "We are super proud and Excited to see VoerEir's Director Technology featured on #APAC50 this month for a very special edition regarding Top Data Centre And Cloud Influencers From Across Asia Pacific 2020/21. ",
    imgSrc: "news_apac50.jpg",
    link: {
      "Learn More":
        "https://data-economy.com/apac-50-the-top-data-centre-and-cloud-influencers-from-across-asia-pacific-2020-21/",
    },
  },
  {
    heading: "LF Edge welcomes VoerEir",
    description:
      "Voereir is now member in lfedge . Voerier is committed to Edge journey",
    imgSrc: "news_lfedge.jpg",
    link: {
      "Learn More":
        "https://www.lfedge.org/2020/04/30/lf-edge-expands-ecosystem-with-open-horizon-adds-seven-new-members-and-reaches-critical-deployment-milestones/",
    },
  },
  {
    heading: "Touchstone 3.0 Released",
    description:
      "VoerEir has released touchstone 3.0 with the capability to benchmark Kubernetes (K8s) environments along with OpenStack environments.",
    imgSrc: "news_touchstone3.jpg",
    link: {
      "Intel Newsletter":
        "https://networkbuilders.intel.com/newsletter/april2020",
      "Learn More": "https://www.voereir.com/release_3.0.0.png",
    },
  },
  {
    heading: "Data Economy 30 under 30",
    description:
      "Voereir's Senior System Architect is listed in Data Economy's 30 under 30 Influencers in DataCentres and Cloud. A list of 30 young talented people who are leading the data center and cloud industries in a new and innovative direction.",
    imgSrc: "news_dataeconomy.jpg",
    link: {
      "Learn More":
        "https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&edid=643864ce-d840-49b4-ac33-7ec2b1e552f3&pnum=21",
    },
  },
  {
    heading: "Akraino Edge Stack community Workshop",
    description:
      "The Linux Foundation Akraino Edge Stack community conducted a workshop on December 17th at the BITS Pilani, Campus in Goa, India. This workshop was co-located with IEEE International Conference on Advanced Networks and Telecommunications Systems. VoerEir actively participated in this",
    imgSrc: "news_linux_foundation_akraino.jpg",
    link: {
      "Learn More": "https://lnkd.in/fJZj7JA",
    },
  },
  {
    heading: "Intel Winners Circle Partner Award ",
    description:
      "VoerEir AB is very proud to be awarded the Intel® Winners Circle Partner Award at the Intel® Network Builders Summit. The summit was held in conjunction with the SDN NFV World Congress in the Hague, Netherlands, on 14th October 2019. This marks a milestone in the long association of VoerEir AB with Intel® Network Builders, and contribution of VoerEir AB to the industry. VoerEir is very proud to be awarded with Intel Winners Circle Award on 14th Oct 2019.",
    imgSrc: "news_intel_network_builder.jpg",
    link: {
      "Learn More":
        "https://soundcloud.com/intelchipchat-networkinsights/voereir/",
      "VoerEir Award": "https://networkbuilders.intel.com/ecosystem/voereir",
      "Circle Partner": "https://networkbuilders.intel.com/winnerscircle",
    },
  },
  {
    heading: "Critical NFVI Performance - KPIs to Validate",
    description:
      "Watch the recording of the webinar on Critical NFVI Performance - KPIs to Validate by our CTO Thomas Lindquist from the session held on the 3rd of October, 2019 at 6:00 PM CET. An opportunity to gain deep insights from the industry veteran on NFVI Performance.",
    imgSrc: "news_critical_nfvi.jpg",
    link: {
      "Watch here":
        "https://networkbuilders.intel.com/social-hub/webcast/critical-nfvi-performance-kpis-to-validate",
    },
  },
  {
    heading: "Addressing NFV Infrastructure",
    description:
      "Touchstone: Addressing NFV Infrastructure - Intel® Chip Chat Network Insights episode 225 by Intel Chip Chat: Network",
    imgSrc: "news_addressing_nfv.png",
    link: {
      "Learn More":
        "https://soundcloud.com/intelchipchat-networkinsights/voereir/",
    },
  },
  {
    heading: "OPNFV Verified",
    description:
      "We are proud to announce that VoerEir AB Lab is now OPNFV Verified! For more details feel free to reach us at contact@voereir.com.",
    imgSrc: "news_opnfv_verified.png",
    link: {
      "Learn More": "https://www.lfnetworking.org/ovp/",
    },
  },
  {
    heading: "Jio and VoerEir",
    description:
      "Jio and VoerEir have during the past year cooperated around defining a list of NFVI- KPIs which determine the performance of VNFs deployed on top of that NFVI. This white paper describes our way of reasoning when creating the list and gives examples of detailed definitions for different kinds of KPIs.",
    imgSrc: "news_jio_kpi.png",
    link: {
      "Learn More":
        "https://drive.google.com/file/d/1NE3XJu_ruZnPHVDR5BuOblpdgMcFYNYl/view",
    },
  },
  {
    heading: "SDN & NFV India Congress 2019",
    description:
      "VoerEir AB is proud to be Gold Sponsor for 3rd Annual SDN & NFV India Congress 2019. See you all at Conference on #14June",
    imgSrc: "news_global_sponsor.png",
  },
]
